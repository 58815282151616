export const getTimeZone = async (lat, lng) => {
  const apiKey = "AIzaSyCW4uEcxzMboANlqwcLeA870_MJakvfdxo";
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(
    Date.now() / 1000
  )}&key=${apiKey}`;

  const response = await fetch(url);
  const data = await response.json();

  return data;
};
