import React, { useRef, useState, useEffect, useCallback } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { config } from "../Helpers/Constants";

const InputField = ({
  go,
  placeholder,
  country,
  setCoordinates,
  getLatLng,
}) => {
  const demoDomain = config.DEMO_DOMAIN;
  const domainName = window.location.hostname;
  const [autocomplete, setAutocomplete] = useState(null);
  const originRef = useRef();
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  useEffect(() => {
    if (window.google && window.google.maps) {
      setIsGoogleLoaded(true);
    } else {
      const checkGoogle = setInterval(() => {
        if (window.google && window.google.maps) {
          setIsGoogleLoaded(true);
          clearInterval(checkGoogle);
        }
      }, 100);
      return () => clearInterval(checkGoogle);
    }
  }, []);

  const onLoad = useCallback((instance) => {
    setAutocomplete(instance);
  }, []);

  const handleAutoComplete = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error("Autocomplete returned no geometry.");
        return;
      }

      const { lat, lng } = place.geometry.location;
      getLatLng(lat(), lng());

      const locationData = {
        [`${go}_location`]: originRef.current?.value || "",
        [`${go}_lat`]: lat(),
        [`${go}_lng`]: lng(),
      };

      setCoordinates((prev) => ({ ...prev, ...locationData }));
    }
  }, [autocomplete, go, setCoordinates]);

  if (!isGoogleLoaded) {
    return <p>Loading...</p>;
  }

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={handleAutoComplete}
      options={
        domainName !== demoDomain
          ? {
              componentRestrictions: {
                country: country?.length > 0 ? country : undefined,
              },
            }
          : undefined
      }
    >
      <input
        type="text"
        className="form-control br-0"
        required
        placeholder={placeholder}
        ref={originRef}
        // value={inputValue}
      />
    </Autocomplete>
  );
};

export default InputField;
