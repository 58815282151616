export const calculateMinMaxTimes = (dateTimeString) => {
  const date = new Date(dateTimeString?.replace(" ", "T") + "Z");

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const isPM = hours >= 12;
  const period = isPM ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  hours = hours % 12 || 12; // if hours is 0 (midnight), make it 12

  // Format minutes to always show two digits
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // console.log(`Time: ${hours}:${formattedMinutes} ${period}`);
  return { minHour: hours, minMinute: formattedMinutes, period };
};
